import { type OrganizationNumber, withPrefix } from '#shared/organization-number'
import { FetchError } from 'ofetch'
import { parse } from 'valibot'

export function useOrganization(
  organizationNumber: MaybeRef<OrganizationNumber | undefined | null>,
) {
  const { $coreApi } = useNuxtApp()

  return useQuery({
    queryKey: ['organization', organizationNumber],
    enabled: computed(() => !!unref(organizationNumber)),
    async queryFn({ signal }) {
      const rawOrganizationNumber = unref(organizationNumber)
      if (!rawOrganizationNumber) {
        return null
      }

      try {
        const res = await $coreApi.ecommerce(`/organizations/${withPrefix(rawOrganizationNumber)}`, { signal })
        return parse(organizationSchema, res)
      }
      catch (e) {
        if (e instanceof FetchError && e.status === 404) {
          return null
        }

        throw e
      }
    },
  })
}
