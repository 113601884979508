import type { InferOutput } from 'valibot'
import { orgNoSchema } from '#shared/organization-number'
import { array, email, fallback, nullish, number, object, picklist, pipe, string } from 'valibot'

const orgSchema = object({
  name: string(),
  number: orgNoSchema,
})

export type CoreOrganization = InferOutput<typeof orgSchema>

export const userSchema = object({
  userId: string(),
  firstName: nullish(string()),
  lastName: nullish(string()),
  fullName: nullish(string()),
  source: picklist([
    'LOCAL',
    'FEIDE',
    'VIPPS',
  ]),
  username: string(),
  role: fallback(
    array(
      picklist([
        'student',
        'teacher',
      ]),
    ),
    [],
  ),
  email: fallback(
    nullish(
      pipe(
        string(),
        email(),
      ),
    ),
    null,
  ),
  homeOrganization: nullish(orgSchema),

  // There is quite some more fields here, but none we care about
})

export type SessionUserInfoDto = InferOutput<typeof userSchema>

export const organizationSchema = object({
  organizationNumber: orgNoSchema,
  numberOfPupils: nullish(number()),
  numberOfPupilsOverride: nullish(number()),
  minimumLicenses: nullish(number()),
  hasDiscounts: array(
    object({
      discountType: fallback(
        picklist([
          'AUNIVERS_SCHOOL_LICENSE_OWNER',
          'UNKNOWN',
        ]),
        'UNKNOWN',
      ),
      eans: array(string()),
    }),
  ),
})
