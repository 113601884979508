/**
 * Retrieves license information in relation to the logged-in user's company account.
 */
export function useLicenseInformation() {
  const { user } = useUserSession()

  const { data: organization } = useOrganization(
    computed(() => user.value?.homeOrganization?.number),
  )

  const count = computed(
    () =>
      organization.value?.minimumLicenses
      ?? organization.value?.numberOfPupilsOverride
      ?? organization.value?.numberOfPupils
      ?? 0,
  )

  // TODO: Should this be dynamic?
  const threshold = computed(() => 5)

  // TODO: Implement this
  const consumption = computed(() => ({
    users: count.value,
    deadline: new Date(),
  }))

  return {
    count,
    threshold,
    showPrices: computed(() => count.value <= threshold.value),
    consumption,
  }
}
